<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>
                        
                        <div class="card" style="z-index: 2;">
                            <!-- ==========主要內容區========== -->
                            <br/>
                            <v-row>
                                <v-col class="px-10">
                                    <v-text-field label="事業單位名稱" 
                                        v-model="orgName"
                                        outlined 
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col class="px-10">
                                    <v-text-field label="姓名" 
                                        v-model="name"
                                        outlined 
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="px-10">
                                    <v-text-field label="帳號" 
                                        v-model="account"
                                        outlined 
                                        hide-details>
                                    </v-text-field>
                                </v-col>
                                <v-col class="px-10">
                                    <v-select
                                        :items="statusItems"
                                        label="狀態"
                                        v-model="status"
                                        dense
                                    ></v-select>
                                </v-col>
                            </v-row>
                            
                            <v-row>
                                <v-col class="px-10">
                                    <v-btn class="mr-4 white--text" color="#37906C" @click="toCreate()">
                                        <v-icon >mdi-note-plus</v-icon>新增
                                    </v-btn>
                                </v-col>
                            </v-row>
                
                            <v-data-table
                            :headers="headers"
                            :items="datalist"
                            :items-per-page="listPageSize"
                            height="800px"
                            hide-default-footer
                            disable-sort
                            class="mx-12"> 
                                <template v-slot:[`item.memberdel`]={item}>
                                    <v-icon @click.stop="toDel(item)">mdi-delete-forever</v-icon>
                                </template>
                                <template v-slot:[`item.memberedit`]={item}>
                                    <v-icon @click.stop="toEdit(item)" >mdi-square-edit-outline</v-icon>
                                </template>
                            </v-data-table>
                        
                            <!-- ==========主要內容區========== -->
                        </div>

                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading"  style="z-index:9999"></loadingMask>
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin';
import loadingMask from '@/components/public/loadingMask.vue'
import {getMemberList, delMember} from "@/api/hyirecoApi";

export default {
  name: 'memberList',
  mixins: [commonMixin],
  created(){
        this.authValidate();
        this.auth = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + this.getTokenWithSession(),
            }
        };
  },
  destroyed() {
        clearInterval(this.interval);
  },
  mounted(){
      //console.log("loginAuth" + JSON.stringify(this.loginAuth));
      this.userName = this.loginAuth.memberName;
      this.getDataList();
  },
  computed: {
    headers() {
      return [
          {
            text: '事業單位名稱',
            align: 'left',
            value: 'orgnization.name',
            class: "subtitle-2 font-weight-black",
          },
          { text: '姓名', value: 'name', class: "subtitle-2 font-weight-black",width:120,},
          { text: '帳號', value: 'account', class: "subtitle-2 font-weight-black",width:100,},
          { text: '狀態', value: 'statusText', class: "subtitle-2 font-weight-black",width:100,},
          { text: '建立日期', value: 'createDate', class: "subtitle-2 font-weight-black",width:100,},
          { text: '刪除', value: 'memberdel', class: "subtitle-2 font-weight-black",width:80, },
          { text: '維護', value: 'memberedit', class: "subtitle-2 font-weight-black",width:80,},
      ]
    },
  },
  methods: {
    getDataList: function() { 
        var vm = this;
        vm.isLoading = true;
        
        getMemberList(vm.queryRequest, vm.auth).then(function(response) {
            if ("200" == response.status) { 
                var responseData = response.data;
                
                if (responseData.statusCode === "1") {
                    var data = responseData.queryPager.resultList;
                    vm.datalist = data.map(function(item){
                        switch (item.status) {
                        case "Y":
                            item.statusText="啟用";
                            break;
                        case "N":
                            item.statusText="停用";
                            break;
                        default:
                            item.statusText="";
                        }
                        return item;
                    });
                } else {
                    vm.datalist = [];
                    if (responseData.statusCode !== "2") vm.$swal(responseData.message);
                }
            }
            vm.isLoading = false;
        });
       
    },
    toEdit: function(value) {
        let vm = this;
        vm.$router.push({
            path: "/member/memberEdit/" + value.uuid
        });
    },
    toDel: function(value) {
        let vm = this;
        vm.$swal({
            title: "<font face='Microsoft JhengHei'>請確認</font>",
            html: "<font face='Microsoft JhengHei'>確認後將立即刪除 " + value.name + "</font>",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "<font face='Microsoft JhengHei' color='#fff'>確認</font>",
            cancelButtonText: "<font face='Microsoft JhengHei' color='#fff'>取消</font>",
            confirmButtonClass: "btn btn-success btn-fill",
            cancelButtonClass: "btn btn-danger btn-fill",
            buttonsStyling: false
        }).then(function(result) {
            if (result.isConfirmed) {
                vm.isLoading = true;
                var uuid = value.uuid;

                delMember(uuid, vm.auth).then(function(response) {
                    if ("200" == response.status) { 
                        var responseData = response.data;
                        //console.log("response = " + JSON.stringify(responseData));
                        vm.$swal(responseData.message);

                        if (responseData.statusCode === "1") {
                            vm.datalist = vm.datalist.filter(function(item){
                                return item.uuid !== value.uuid;
                            });
                        }
                    }
                    vm.isLoading = false;
                });    
            }
        });
    },
    toCreate: function() {
        this.$router.push({
            path: "/member/memberEdit/init"
        });
    },
    keyupDelay: function() {
        //用於延遲查詢，避免value改變時連續送出request
        let vm = this;
        clearTimeout(vm.timer);
        
        vm.timer = setTimeout(function() {
            vm.getDataList();
        }, 800);
    },
  },
  watch: {
      orgName: function(value){
          this.queryRequest.queryObject.orgName = value;
          this.keyupDelay();
      },
      name: function(value){
          this.queryRequest.queryObject.name = value;
          this.keyupDelay();
      },
      account: function(value){
          this.queryRequest.queryObject.account = value;
          this.keyupDelay();
      },
      status: function(value){
          this.queryRequest.queryObject.status = value;
          this.getDataList();
      },
  },
  data: () => ({
    statusItems: [{text: "全部", value:""},{text: "啟用", value:"Y"},{text: "停用", value:"N"}],
    userName: "",
    datalist: [],
    listPageSize: 10,
    orgName: "",
    name: "",
    account: "",
    status: "",
    timer: null,
    isLoading: false,
    auth:{},
    queryRequest: {
        queryObject: {
            role: "O",
            orgName: "",
            name: "",
            account: "",
            status: "",
        },
        queryPager: {
            currentPage: 1,
            pageSize: 10,
        },
    },
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
  },
}
</script>

<style scoped>

</style>
